import service from './network';

export function queryRouteList(data) {
    return service.request({
        url: '/route/query',
        method: 'post',
        data
    })
}

export function updateRoute(data) {
    return service.request({
        url: '/route/update',
        method: 'put',
        data
    })
}

export function deleteRoute(data) {
    return service.request({
        url: '/route/delete',
        method: 'delete',
        data
    })
}

export function queryChildRoute(data) {
    return service.request({
        url: '/route/parent',
        method: 'get',
        params: data
    })
}

export function queryRoleList(data) {
    return service.request({
        url: '/role/query',
        method: 'post',
        data
    })
}

export function queryRoleByCode(data) {
    return service.request({
        url: '/role/query/code/' + data,
        method: 'get',
    })
}

export function updateRole(data) {
    return service.request({
        url: '/role/update',
        method: 'put',
        data
    })
}

export function deleteRole(data) {
    return service.request({
        url: '/role/delete',
        method: 'delete',
        data
    })
}

export function queryRoutePermissionList(data) {
    return service.request({
        url: '/route/query/permission',
        method: 'post',
        data
    })
}

export function updateRoutePermission(data) {
    return service.request({
        url: '/route/permission/update',
        method: 'post',
        data
    })
}

export function deleteRoutePermission(data) {
    return service.request({
        url: '/route/permission/delete',
        method: 'delete',
        data
    })
}

export function queryPermissionList(data) {
    return service.request({
        url: '/permission/queryPermission',
        method: 'post',
        data
    })
}

export function updatePermission(data) {
    return service.request({
        url: '/permission/update',
        method: 'put',
        data
    })
}

export function deletePermission(data) {
    return service.request({
        url: '/permission/delete',
        method: 'delete',
        data
    })
}

export function grantRolePermission(data) {
    return service.request({
        url: '/role/grant/permission',
        method: 'post',
        data
    })
}

export function grantRoleRoute(data) {
    return service.request({
        url: '/role/grant/route',
        method: 'post',
        data
    })
}

export function queryRolePermission(data) {
    return service.request({
        url: '/role/query/rolePermission',
        method: 'get',
        params: data
    })
}

export function queryUserList(data) {
    return service.request({
        url: '/management/security/user/query',
        method: 'post',
        data
    })
}

export function grantUserRole(data) {
    return service.request({
        url: '/management/security/user/grant/role',
        method: 'post',
        data
    })
}

export function queryUserRole(data) {
    return service.request({
        url: '/management/security/user/query/role',
        method: 'post',
        data
    })
}

export function deleteUser(data) {
    return service.request({
        url: '/management/security/user/delete',
        method: 'delete',
        data
    })
}

export function updateUser(data) {
    return service.request({
        url: '/management/security/user/update',
        method: 'put',
        data
    })
}

export function resetUserPassword(data) {
    return service.request({
        url: '/management/security/user/reset/password',
        method: 'put',
        params: data
    })
}

export function disableUser(data) {
    return service.request({
        url: '/management/security/user/disable',
        method: 'put',
        data
    })
}

export function deleteUserCache(data) {
    return service.request({
        url: '/management/security/user/cache/delete',
        method: 'delete',
        data
    })
}

export function grantUserModule(data) {
    return service.request({
        url: '/management/security/user/grant/module',
        method: 'post',
        data
    })
}

export function queryUserModule(data) {
    return service.request({
        url: '/management/security/user/query/module',
        method: 'post',
        data
    })
}

export function updateRouteDerivedApi(data) {
    return service.request({
        url: '/route/update/derived',
        method: 'put',
        data
    })
}
