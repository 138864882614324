<template>
  <div>
    <a-form layout="inline">
      <a-form-item label="权限名称">
        <a-input v-model="query.permissionName" placeholder="请输入权限名称"/>
      </a-form-item>

      <a-form-item label="请求方式">
        <a-select
            v-model="query.method"
            placeholder="请选择请求方式"
            style="width: 150px;"
        >
          <a-select-option :key="-1" :value="undefined">全部</a-select-option>
          <a-select-option v-for="method in methodList" :key="method.value" :value="method.value">
            {{ method.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>
    </a-form>

    <a-table bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 24px;"
             @change="handleTableChange">
      <span slot="action" slot-scope="text, record">
        <a @click="editPermission(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确认删除？"
            @confirm="deletePermission(record)"
        >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <permission-edit ref="edit" v-bind:item="currentPermission" @success="queryPermissionList"/>
  </div>
</template>

<script>
import permissionEdit from './component/permissionEdit'
import _ from "lodash";
import {deletePermission, deleteUserCache, queryPermissionList} from "@/api/route";

export default {
  name: "permissionList",
  components: {permissionEdit},
  data() {
    return {
      query: {
        permissionName: undefined,
      },
      columns: [
        {
          title: '权限编号',
          dataIndex: 'permissionCode',
          key: 'permissionCode',
        },
        {
          title: '权限名称',
          dataIndex: 'permissionName',
          key: 'permissionName',
        },
        {
          title: '请求方式',
          dataIndex: 'method',
          key: 'method',
        },
        {
          title: '权限',
          dataIndex: 'permission',
          key: 'permission',
        },
        {
          title: '字典',
          dataIndex: 'dictionary',
          key: 'dictionary',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      currentPermission: undefined,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      emptyPermission: {
        id: undefined,
        permissionCode: undefined,
        represent: undefined,
        permissionName: undefined,
      },
      methodList: _.map(['GET', 'POST', 'PUT', 'PATCH', 'DELETE'], value => {
        return {
          value: value, label: value
        }
      })
    }
  },
  mounted() {
    this.queryPermissionList()
  },
  methods: {
    editPermission(permission) {
      this.currentPermission = permission
      this.$refs.edit.toggleVisible()
    },
    async queryPermissionList() {
      this.loading = true

      let data = await queryPermissionList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryPermissionList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryPermissionList()
    },
    async deletePermission(permission) {
      await deletePermission([permission.id])

      this.$message.success('删除成功')
      await this.queryPermissionList()
      await deleteUserCache()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (permission, index) => {
        return {
          key: permission.permissionCode,
          ...permission,
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
