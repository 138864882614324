<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="权限名称">
        <a-input
            v-decorator="['permissionName', { rules: [], initialValue: item && item.permissionName }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import {deleteUserCache, updatePermission} from "@/api/route";

export default {
  name: "permissionEdit",
  props: {
    item: {}
  },
  mounted() {
  },
  data() {
    return {
      title: this.item ? '修改权限' : '新增权限',
      visible: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updatePermission({
            ...this.item,
            ...values,
          })

          await deleteUserCache()
          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改权限' : '新增权限'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
